// @flow

import React from "react";
import { Layout, InspectionPointLayout, withPrivateRoute } from "@containers";

import Assignments from "@components/Shared/Assignments";

/**
 * Home Page
 */
const Requests = (props: *) => (
    <Layout {...props}>
        <InspectionPointLayout>
            <Assignments locationSpecific location={props.location} />
        </InspectionPointLayout>
    </Layout>
);

export default withPrivateRoute(
    Requests,
    "/inspectiepunt/:inspectionPointId/opdrachten",
);
